module.exports = {
  siteTitle: 'Sarhad Salam | Software Engineer Student',
  siteUrl: `sarhad.me`,
  description: `My Personal Website using to showcase my projects, and experience.`,
  keywords: `Software, Engineer, Engineering, Web, Developer, Internship,
   React, LowLevel, Cybersecurity, Artificial Intelligence,
   Intelligence, Artificial`,
  siteLanguage: `EN`,
  backgroundColor: `#222629`,
  darkGrey: `#474B4F`,
  lightGrey: `rgba(255, 255, 255, 0.65)`,
  lightGreen: `#86C232`,
  darkGreen: `#61892F`,
  primaryText: `#ffffff`
}
