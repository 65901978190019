import React from 'react'
import { css } from 'emotion'
import { Typography, Layout, Row, Col } from 'antd'
const config = require('src/config/index')

const { Title, Text, Paragraph } = Typography

export default class About extends React.Component<{}, {}> {
  render() {
    return (
      //   <Layout>
      // <Content style={{ margin: '0 16px' }}>
      <Row
        type="flex"
        justify="start"
        align="middle"
        className={css`
          width: -webkit-fill-available;
        `}
      >
        <Col xs={22} sm={22} md={22} lg={22} xl={12} xxl= {8}>
          <div
            className={css`
              & p,
              & h1 {
                margin: 0;
              }
              & .ant-typography + h1.ant-typography {
                margin: 0;
              }
              & div.ant-typography {
                margin-bottom: 0;
                line-height: 1.2;
              }
            `}
          >
            <Paragraph
              className={css`
                color: ${config.lightGreen};
              `}
            >
              Hi, My name is,
            </Paragraph>
            <Title
              level={1}
              className={css`
                color: ${config.primaryText} !important;
              `}
            >
              <span
                className={css`
                  text-decoration: underline;
                  color: ${config.lightGreen} !important;
                `}
              >
                <span
                  className={css`
                    text-decoration: none;
                    color: ${config.primaryText} !important;
                  `}
                >
                  Sarhad
                </span>
              </span>{' '}
              Salam.
            </Title>
            <Title
              className={css`
                color: ${config.lightGrey} !important;
              `}
              level={1}
            >
              I build software for all platforms.
            </Title>
            <Title
              className={css`
                color: ${config.lightGrey} !important;
              `}
              level={4}
            >
              I'm a computer engineering student in my junior year at University
              of Toronto. I am interested in cybersecurity, software development
              and artificial intelligence.
            </Title>
          </div>
        </Col>
      </Row>
      // </Content>
      //   </Layout>
    )
  }
}
