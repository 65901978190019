import anime from 'animejs'
import { Icon, Layout, Menu, Tooltip } from 'antd'
import { css } from 'emotion'
import React from 'react'
import { ReactComponent as LeetIcon } from '../img/leet.svg'
import { ReactComponent as StackIcon } from '../img/stack.svg'
const config = require('src/config/index')
const { Sider } = Layout

interface SocialBarState {
  broken: boolean;
}

export default class SocialBar extends React.Component<{}, SocialBarState> {
  constructor(props: {}) {
    super(props)

    this.state = {
      broken: false
    }
  }

  onBreakpoint = (broken: boolean) => {
    this.setState({ broken })
  }

  componentDidMount() {
    // Create a timeline with default parameters
    anime({
      targets: ` #social-animation-container ul li i,
         #social-animation-container div:nth-child(1)`,
      translateX: [-50, 0],
      delay: anime.stagger(50, { start: 200 }),
      duration: 400,
      easing: 'easeOutExpo'
    });

    anime({
      targets: `#email-container`,
      translateX: [-10, 0],
      rotate: [-85, -90],
      duration: 50,
      easing: 'easeOutExpo'
    });
  }

  render() {
    return (
      <Sider
        collapsedWidth={this.state.broken ? 0 : 80}
        collapsed={true}
        defaultCollapsed={true}
        className={css`
          min-height: 100vh;
          & div.ant-layout-sider-children {
            width: inherit;
            position: fixed;
          }
        `}
        breakpoint="sm"
        trigger={null}
        onBreakpoint={this.onBreakpoint}
      >
        <div
          className={css`
            transform: rotate(-90deg);
            margin-top: 80px;
            margin-left: 8px;
          `}
          id="email-container"
        >
          <Tooltip placement="left" title="Send me an Email!">
            <p>
              <a
                href="mailto:jobs@sarhad.me"
                target="_blank"
                className={css`
                  color: ${config.lightGrey};
                  &:hover {
                    color: ${config.lightGreen};
                  }
                `}
              >
                jobs@sarhad.me
              </a>
            </p>
          </Tooltip>
        </div>
        <div
          id="social-animation-container"
          className={css`
            position: fixed;
            bottom: 0;
            & .ant-menu-item-active i {
              color: ${config.lightGreen};
            }
            & li.ant-menu-item {
              margin-left: -5px;

              & i svg {
                width: 1.5em;
                height: 1.5em;
              }
            }

          `}
        >
          <Menu theme="dark" mode="inline" selectable={false}>
            <Menu.Item key="1">
              <a href="https://www.linkedin.com/in/sarhad" target="_blank">
                <Icon type="linkedin" theme="filled" />
              </a>
              <span>LinkedIn</span>
            </Menu.Item>
            <Menu.Item key="2">
              <a href="https://www.github.com/sarhadsalam" target="_blank">
                <Icon type="github" theme="filled" />
              </a>
              <span>GitHub</span>
            </Menu.Item>
            <Menu.Item key="3">
              <a href="https://leetcode.com/sarhad" target="_blanket">
                <Icon component={LeetIcon} />
              </a>
              <span>Leet Code</span>
            </Menu.Item>
            <Menu.Item key="4">
              <a
                href="https://stackoverflow.com/users/5511392/sarhad-salam"
                target="_blank"
              >
                <Icon component={StackIcon} />
              </a>
              <span>Stack Overflow</span>
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
    )
  }
}
