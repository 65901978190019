import React from 'react'

import { css } from 'emotion'
import config from 'src/config/index'
import Head from 'src/components/head'
import { Layout } from 'antd'
import Navbar from '../components/navbar'
import Social from '../components/social'
import About from 'src/components/about';


export default class App extends React.Component<{}, {}> {
  render() {
    return (
      <div className={css`
        & .ant-menu-dark .ant-menu-item:hover {
          color: ${config.lightGreen};
        }
      `}>
        <Head />
        <Layout>
          <Navbar />
          <About/>
          <Social />
        </Layout>
      </div>
    )
  }
}
