import anime from 'animejs'
import config from '../config/index'
// tslint:disable-next-line: ordered-imports
import { Avatar, Icon, Layout, Menu } from 'antd'
import { css } from 'emotion'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'

const { Sider } = Layout
interface NavbarState {
  collapsed: boolean;
  broken: boolean;
  init: boolean;
  leftCollapse: number;
  rightCollapse: number;
}

export default class Navbar extends React.Component<{}, NavbarState> {
  constructor(props: {}) {
    super(props)
    this.state = {
      collapsed: false,
      broken: false,
      init: true,
      leftCollapse: 3,
      rightCollapse: 65
    }
  }

  componentDidUpdate() {
    if (!this.state.init) {
      anime({
        easing: 'easeOutExpo',
        targets: '#nav-animation-container div:nth-child(1) span',
        marginLeft: this.state.collapsed
          ? [this.state.rightCollapse, this.state.leftCollapse]
          : [this.state.leftCollapse, this.state.rightCollapse],
        duration: 400
      })
    }
  }

  componentDidMount() {
    // Create a timeline with default parameters
    anime({
      targets: `#nav-animation-container ul li i,
         #nav-animation-container div:nth-child(1)`,
      translateX: [50, 0],
      delay: anime.stagger(50, { start: 200 }),
      duration: 400,
      easing: 'easeOutExpo'
    })
  }

  onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed, init: false })
  }

  onBreakpoint = (broken: boolean) => {
    this.setState({ broken, collapsed: true })
  }

  render() {
    const element = (data: any) => {
      return (
        <Sider
          collapsible
          collapsed={this.state.collapsed}
          collapsedWidth={!this.state.broken ? 80 : 0}
          onCollapse={this.onCollapse}
          defaultCollapsed={true}
          className={css`
            min-height: 100vh;
            & div.ant-layout-sider-children {
              width: inherit;
              position: fixed;
            }
            & .ant-menu.ant-menu-dark .ant-menu-item-selected {
              background-color: inherit;
              & i {
                color: ${config.lightGreen};
              }
              & span {
                color: ${config.lightGreen};
              }
            }
          `}
          breakpoint="sm"
          onBreakpoint={this.onBreakpoint}
        >
          <div id="nav-animation-container">
            <div
              className="logo"
              style={{
                height: '32px',
                margin: '16px'
              }}
            >
              <Avatar
                style={{
                  marginLeft:
                    (this.state.collapsed
                      ? this.state.leftCollapse
                      : this.state.rightCollapse) + 'px'
                }}
                shape="square"
                size="large"
                src={data.allFile.edges[0].node.publicURL}
                alt="Sarhad's Logo"
              />
            </div>
            <Menu
              theme="dark"
              className={css`
                margin-top: 5px;
                & svg {
                  width: 1.5em;
                  height: 1.5em;
                  margin-left: -5px;
                }
              `}
              defaultSelectedKeys={['1']}
              mode="inline"
            >
              <Menu.Item key="1">
                <Icon type="user" />
                <span>About</span>
              </Menu.Item>
              <Menu.Item key="2">
                <Icon type="desktop" />
                <span>Experience</span>
              </Menu.Item>
              <Menu.Item key="3">
                <Icon type="shop" />
                <span>Projects</span>
              </Menu.Item>
              <Menu.Item key="4">
                <Icon type="idcard" />
                <span>Resume</span>
              </Menu.Item>
            </Menu>
          </div>
        </Sider>
      )
    }
    return (
      <StaticQuery
        query={graphql`
          {
            allFile(filter: { name: { eq: "portfolio_logo_500" } }) {
              edges {
                node {
                  publicURL
                }
              }
            }
          }
        `}
        render={data => element(data)}
      />
    )
  }
}
